export default {
  "contents": [{
    "id": "1bd7a0f9-ca7b-4c2d-a2da-18cca92457f6",
    "name": "Collection2",
    "visibility": "visible",
    "events": {"devices": ["all"]},
    "allowedTypes": null,
    "editable": true,
    "builtIn": false
  }], "totalSize": 1
};
