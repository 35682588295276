import { store } from '../../utils/constants';

export const storeSubscriptionsList = (_payload) => {
  return {
    type: store.partnerlinkRnPm.STORE_PERMISSION_SUBSCRIPTIONS_LIST,
    payload: _payload,
  };
};

export const storeSubscriptionsRead = (_payload) => {
  return {
    type: store.partnerlinkRnPm.STORE_PERMISSION_SUBSCRIPTIONS_READ,
    payload: _payload,
  };
};

export const storeSubscriptionsWrite = (_payload) => {
  return {
    type: store.partnerlinkRnPm.STORE_PERMISSION_SUBSCRIPTIONS_WRITE,
    payload: _payload,
  };
};

export const storeSubscriptionsDelete = (_payload) => {
  return {
    type: store.partnerlinkRnPm.STORE_PERMISSION_SUBSCRIPTIONS_DELETE,
    payload: _payload,
  };
};
