import styled from 'styled-components';
import React, { useEffect } from 'react';
import { Accordion, IconWarningAlt } from '@veneer/core';
import { accordionHelper } from '../../veneerHelper';

const NoItemsAccordion = (props: { anchorId: string; title: string; noItemsMessage: string }) => {
  const { accordionState, accordionSetState, accordionFunc } = accordionHelper();

  useEffect(() => {
    const items = createAccordionItems();
    accordionSetState.setItems(items);
  }, []);

  const createAccordionItems = () => {
    return [
      {
        content: (
          <NoItemsArea>
            <IconWrapper>
              <IconWarningAlt size={24} />
            </IconWrapper>

            <div>{props.noItemsMessage}</div>
          </NoItemsArea>
        ),
        header: {
          centralArea: <AccordionTitle>{props.title}</AccordionTitle>,
          id: props.anchorId,
        },
        id: `${props.anchorId}-body`,
        expanded: true,
      },
    ];
  };

  return (
    <Accordion
      hoverable
      items={accordionState.items}
      onExpand={accordionFunc.handleExpand}
      onCollapse={accordionFunc.handleCollapse}
    />
  );
};

// default values about props
NoItemsAccordion.defaultProps = {
  noItemsMessage: 'No Items Found',
};

const NoItemsArea = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 20px;
  background: rgba(33, 33, 33, 0.1);
  margin-bottom: 3px;
`;

const AccordionTitle = styled.div`
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #212121;
`;

export default NoItemsAccordion;
