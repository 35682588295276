export default {
  "deviceId": "AQAAAAF6xPeHFAAAAAEBLgfz",
  "lastUpdatedAt": "2022-05-23T11:49:31.855Z",
  "identity": {
    "lastUpdateAt": null,
    "serialNumber": "V6106R0FMV",
    "deviceUuid": "db7059b0-3600-4359-93b4-bd7a4a5164e0",
    "groups": [],
    "makeAndModel": {
      "name": "HP Color LaserJet Pro M454dw",
      "number": "W1Y45A",
      "series": "HP Color LaserJet Pro M454"
    },
    "firmwareVersion": "2019.09.01",
    "location": "",
    "friendlyName": "",
    "supplyType": "toner",
    "colorSupported": true,
    "bizModel": "",
    "benefitsControlModel": "none",
    "platformIdentifier": "gen2",
    "displayProfile": "",
    "bleHPSpecVersion": "",
    "deviceSegment": "SMB",
    "programLevel": "",
    "countryRegion": null,
    "language": null,
    "assetNumber": ""
  },
  "status": {
    "lastUpdateAt": null,
    "connectionState": "offline",
    "connectionStateCode": 0,
    "powerState": "",
    "scanADFState": "",
    "scanState": "",
    "printerState": "",
    "printerStateReasons": [],
    "printerStateSeverity": "",
    "acceptingJobs": false
  },
  "ownership": {
    "accountId": "3c6f64fa-76d5-41fe-b1dd-b06634968c01",
    "userId": null,
    "ownershipId": "8050b38c-99e0-4fe6-aa50-71dccdfdcad1",
    "fqTenantId": null
  },
  "images": [{"url": "https://devices.us1.api.ws-hp.com/devices/v1/images/970547c3c6fa317301756e7ef20f7c2a/42x26.png"}, {"url": "https://devices.us1.api.ws-hp.com/devices/v1/images/970547c3c6fa317301756e7ef20f7c2a/130x102.png"}, {"url": "https://devices.us1.api.ws-hp.com/devices/v1/images/970547c3c6fa317301756e7ef20f7c2a/170x128.png"}, {"url": "https://devices.us1.api.ws-hp.com/devices/v1/images/970547c3c6fa317301756e7ef20f7c2a/300x245.png"}],
  "groups": [{
    "uuid": "f441950b-4116-4a80-9caa-120582ff5dd2",
    "label": "All Devices",
    "parent": null
  }],
  "timestamp": {
    "lastSeenTime": "2021-07-22T05:56:12.000Z",
    "lastRegistrationTime": null,
    "firstRegistrationTime": null,
    "firmwareDate": null,
    "claimTime": "2021-07-20T17:44:48.000Z",
    "claimRevokeTime": "2021-07-20T17:41:18.000Z",
    "deviceTime": null,
    "lastResetTime": null
  },
  "solutionMetadata": null,
  "solutions": ["ws-hp.com/hpsm", "ws-hp.com/smcloud", "ws-hp.com/eprint"],
  "solutionConfig": {
    "cloudShortcutsEnabled": false,
    "cloudShortcutsAvailable": false,
    "eprintEmailAddress": "qgkf88md364@email.devpie.hpeprint.com",
    "eprintSupported": true,
    "printOnTheGoSupport": ""
  },
  "network": null,
  "supplies": {
    "lastUpdateAt": "2022-05-23T06:43:52.021Z",
    "overallLevelStateCode": "",
    "consumables": []
  },
  "tenancy": {
    "lastUpdatedAt": null,
    "fqResourceIdPath": "5c1c2547bf965dee00465a3b/c1a46987-cbd3-45db-b94a-a6544116fc2d/072b5ffd-476b-4290-b66b-828a9029722a/8faa7ca3-687b-4cc6-bbe2-6bd31ed46a96/379dfb46-7822-4cab-9ac0-f7ac20d0f00b/3c6f64fa-76d5-41fe-b1dd-b06634968c01"
  }
}
