import { useState } from 'react';

const accordionHelper = () => {
  const [items, setItems] = useState([]);

  const handleExpand = (event, index) => {
    const updatedData = [...items];
    updatedData[index].expanded = true;
    setItems(updatedData);
  };

  const handleCollapse = (event, index) => {
    const updatedData = [...items];
    updatedData[index].expanded = false;
    setItems(updatedData);
  };

  const accordionFunc = {
    handleExpand,
    handleCollapse,
  };

  const accordionState = {
    items: items,
  };

  const accordionSetState = {
    setItems,
  };

  return {
    accordionFunc,
    accordionState,
    accordionSetState,
  };
};

export default accordionHelper;
