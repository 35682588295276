import React from 'react';
import ShellProps from './shellProps';
import { Stack } from '@jarvis/web-stratus-client';

export const ShellPropsProvider = (props) => {
  return (
    <ShellProps.Provider
      value={{
        ecpDeviceV55: props.ecpDeviceV55 ?? false,
        stack: props.stack ?? Stack.pie,
        useToast: props.useToast(),
        authProvider: props.authProvider,
        navigation: props.navigation,
      }}
    >
      {props.children}
    </ShellProps.Provider>
  );
};
