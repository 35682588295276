import rootReducer from '../src/store';
import { configureStore } from '@reduxjs/toolkit';

export const mockStack = 1;

export const mockStore = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

// export const mockNavigation = createMemoryHistory();
export const mockNavigation = {
  location: {
    pathname: '/en/us/devices/deviceId',
  },
  createHref: (pathname) => {
    return '/';
  },
};

export const mockAuthProvider = {
  getAccessToken: () => new Promise((resolve) => resolve('accessToken')),
  getIDToken: () => new Promise((resolve) => resolve('')),
  onTokenExchangeRequired: () => new Promise((resolve) => resolve('')),
};

export const mockLocalization = { language: 'en', country: 'us' };

export const mockAccessControl = {
  refresh: () => { },
  checkScopes: () => {
    return true;
  },
};
