import React from 'react';
import { useDispatch } from 'react-redux';
import { PartnerlinkContainer } from './PartnerlinkContainer';
import { getPartnerlinkStoreActions } from '../../store/partnerlink/init';
import { getPartnerlinkRnPmActions } from '../../store/partnerlinkRnPm/init';
import { useStoreState } from '../../store/useStoreState';
import { useConstructor } from '../../utils/customHook';
import { setProps } from '../../utils/multiLang';

export const RootComponent = (props) => {
  const { partnerlinkState } = useStoreState();
  const { startRootComponent, deviceId } = partnerlinkState;
  const dispatch = useDispatch();
  const dispatchAll = (list: any[]) => list.forEach((e) => dispatch(e));

  useConstructor(() => {
    setProps(props);

    // init all redux stores
    dispatchAll(getPartnerlinkStoreActions(props));
    getPartnerlinkRnPmActions(props, dispatch).then();
  });

  const didReduxUpdated = () => {
    return startRootComponent && deviceId !== '';
  };

  const renderRootComponent = () => {
    if (didReduxUpdated()) {
      return (
        <div id="device-details-main-content" data-testid="root-component">
          <PartnerlinkContainer {...props} />
        </div>
      );
    } else {
      return <></>;
    }
  };

  return renderRootComponent();
};
