import { isMockUpMode, getMockUpData } from '../..//utils/commonMethods';
import { SubscriptionsApiClient } from '@jarvis/web-stratus-client';

const useGetSubscriptions = async (client: SubscriptionsApiClient, subscriptionOptions) => {
  let response, code, error;

  if (isMockUpMode()) {
    await getMockUpData('subscriptions').then((data) => {
      response = data;
      code = 200;
    });
    return { response, code };
  }

  await client
    .list({
      deviceId: subscriptionOptions.deviceId,
      deviceUuid: subscriptionOptions.deviceUuid,
      deviceTenantId: subscriptionOptions.tenantId,
      productNumber: subscriptionOptions.productNumber,
      serialNumber: subscriptionOptions.serialNumber
    })
    .then((res) => {
      response = res.data;
      code = res.status;
    })
    .catch((err) => {
      if (err.response) {
        response = err.message;
        code = err.response.status;
      } else {
        error = err;
      }
    });

  return { response, code, error };
};

const usePostSubscriptions = async (client: SubscriptionsApiClient, subscriptionOptions) => {
  let response, code, error;

  if (isMockUpMode()) {
    await getMockUpData('subscriptions').then((data) => {
      response = '';
      code = data.some((id) => id === subscriptionOptions.accountId) ? 201 : 200;
    });
    return { response, code };
  }

  await client
    .connect({
      accountId: subscriptionOptions.accountId,
      deviceId: subscriptionOptions.deviceId,
      deviceUuid: subscriptionOptions.deviceUuid,
      deviceTenantId: subscriptionOptions.tenantId,
      productNumber: subscriptionOptions.productNumber,
      serialNumber: subscriptionOptions.serialNumber
    })
    .then((res) => {
      response = res.data;
      code = res.status;
    })
    .catch((err) => {
      if (err.response) {
        response = err.message;
        code = err.response.status;
      } else {
        error = err;
      }
    });

  return { response, code, error };
};

const useDeleteSubscriptions = async (client: SubscriptionsApiClient, subscriptionOptions) => {
  let response, code, error;

  if (isMockUpMode()) {
    await getMockUpData('subscriptions').then((data) => {
      if (data.some((id) => id === subscriptionOptions.accountId)) {
        response = {
          code: 'Ok',
          message: 'Removed Successfully.',
        };
        code = 200;
      } else {
        response = {
          code: 'EL000U0003',
          message: 'Not Found',
        };
        code = 404;
      }
    });
    return { response, code };
  }

  await client
    .disconnect({
      accountId: subscriptionOptions.accountId,
      deviceId: subscriptionOptions.deviceId,
      deviceUuid: subscriptionOptions.deviceUuid,
      deviceTenantId: subscriptionOptions.tenantId,
      productNumber: subscriptionOptions.productNumber,
      serialNumber: subscriptionOptions.serialNumber
    })
    .then((res) => {
      response = res.data;
      code = res.status;
    })
    .catch((err) => {
      if (err.response) {
        response = err.message;
        code = err.response.status;
      } else {
        error = err;
      }
    });

  return { response, code, error };
};

export const subscriptionsApiSvc = {
  useGetSubscriptions,
  usePostSubscriptions,
  useDeleteSubscriptions,
};
