import { store } from '../../utils/constants';

export const storeStartRootComponent = (_payload) => {
  return {
    type: store.partnerlink.START_ROOT_COMPONENT,
    payload: _payload,
  };
};

export const storeDeviceId = (_payload) => {
  return {
    type: store.partnerlink.DEVICE_ID,
    payload: _payload,
  };
};

export const storeDeviceUuid = (_payload) => {
  return {
    type: store.partnerlink.DEVICE_UUID,
    payload: _payload,
  };
};

export const storeProductNumber = (_payload) => {
  return {
    type: store.partnerlink.PRODUCT_NUMBER,
    payload: _payload,
  };
};

export const storeSerialNumber = (_payload) => {
  return {
    type: store.partnerlink.SERIAL_NUMBER,
    payload: _payload,
  };
};

export const storeTenantId = (_payload) => {
  return {
    type: store.partnerlink.TENANT_ID,
    payload: _payload,
  };
};
