import { store } from '../../utils/constants';

const initialState = {
  startRootComponent: false,
  deviceId: '',
  deviceUuid: '',
  productNumber: '',
  serialNumber: '',
  tenantId: ''
};

const partnerlinkReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.partnerlink.START_ROOT_COMPONENT:
      return {
        ...state,
        startRootComponent: action.payload,
      };
    case store.partnerlink.DEVICE_ID:
      return {
        ...state,
        deviceId: action.payload,
      };
    case store.partnerlink.DEVICE_UUID:
      return {
        ...state,
        deviceUuid: action.payload,
      };
    case store.partnerlink.PRODUCT_NUMBER:
      return {
        ...state,
        productNumber: action.payload,
      };
    case store.partnerlink.SERIAL_NUMBER:
      return {
        ...state,
        serialNumber: action.payload,
      };
    case store.partnerlink.TENANT_ID:
      return {
        ...state,
        tenantId: action.payload,
      };
    default:
      return state;
  }
};

export default partnerlinkReducer;
