import styled from 'styled-components';
import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Card, IconInfo, IconCheckCircle, IconWarningAlt, IconXCircle, TextBox, Button } from '@veneer/core';
import apiController from '../../../api/apiController';
import { subscriptionsApiSvc } from '../../../api/Subscriptions';
import { LoadingSection } from '../../../components/Common';
import { shellProps } from '../../../shellProps';
import { useStoreState } from '../../../store/useStoreState';
import { service } from '../../../utils/constants';
import { t } from '../../../utils/multiLang';

interface PartnerLink {
  status: ConnectionStatus;
  id: string;
  message: string;
  anyError: boolean;
}

enum ConnectionStatus {
  UNKNOWN,
  CONNECTED,
  DISCONNECTED,
}

export const PartnerlinkContainer = (props) => {
  const { getApiClient } = apiController();
  const client = getApiClient(service.subscriptions);
  const { useToast } = useContext(shellProps);
  const { partnerlinkState, partnerlinkRnPmState } = useStoreState();
  const { deviceId, deviceUuid, productNumber, serialNumber, tenantId } = partnerlinkState;
  const {
    subscriptionsListRnPm,
    subscriptionsReadRnPm,
    subscriptionsWriteRnPm,
    subscriptionsDeleteRnPm,
  } = partnerlinkRnPmState;
  const [error, setError] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [isRequesting, setIsRequesting] = useState(false);
  const [partnerlinkDetails, setPartnerlinkDetails] = useState<PartnerLink>({
    status: ConnectionStatus.UNKNOWN,
    id: '',
    message: t('status.info'),
    anyError: false,
  });
  const subscriptionOptions = {
    accountId: partnerlinkDetails.id,
    deviceId: deviceId,
    deviceUuid: deviceUuid,
    productNumber: productNumber,
    serialNumber: serialNumber,
    tenantId: tenantId,
  };

  useEffect(() => {
    setIsFetching(true);
    setIsRequesting(false);
    getPartnerlinkDetails();
  }, []);

  useEffect(() => {
    if (error) {
      renderError();
    }
  }, [error]);

  const renderError = () => {
    useToast.addToast({
      id: 'error',
      type: 'negative',
      text: t('error.default'),
    });
  };

  const getPartnerlinkDetails = async () => {
    if (subscriptionsListRnPm && subscriptionsReadRnPm) {
      console.debug('[Partnerlink] apiGetPartnerlinkDetails');

      const partnerlinkInfo = await subscriptionsApiSvc.useGetSubscriptions(
        client,
        subscriptionOptions,
      );

      switch (partnerlinkInfo.code) {
        case 200: // Ok.
          if (partnerlinkInfo.response !== undefined && partnerlinkInfo.response.length > 0) {
            setPartnerlinkDetails({
              status: ConnectionStatus.CONNECTED,
              id: partnerlinkInfo.response[0],
              message: t('status.connected'),
              anyError: false,
            });
          }
          break;
        case 400: // Bad request.
        case 401: // Unauth.
        case 500: // Unexpected error.
          setError(true);
          break;
        default:
          if (partnerlinkInfo.error !== undefined) {
            setError(true);
          }
          break;
      }
      setIsFetching(false);
    } else {
      console.error('[Partnerlink] The scope required to list is not available.');
      setError(true);
    }
  };

  const connect = async () => {
    if (subscriptionsWriteRnPm) {
      console.debug('[Partnerlink] Connecting to Partnerlink...');

      setIsRequesting(true);
      const partnerlinkInfo = await subscriptionsApiSvc.usePostSubscriptions(
        client,
        subscriptionOptions,
      );

      switch (partnerlinkInfo.code) {
        case 200: // Subscription already exists.
        case 201: // Ok.
          setPartnerlinkDetails({
            status: ConnectionStatus.CONNECTED,
            id: partnerlinkDetails.id,
            message: t('status.connected'),
            anyError: false,
          });
          break;
        case 400: // Bad request.
        case 401: // Unauth.
          setError(true);
          break;
        case 500: // Unexpected error.
          setPartnerlinkDetails({
            status: partnerlinkDetails.status,
            id: partnerlinkDetails.id,
            message: t('status.error_connecting'),
            anyError: true,
          });
          break;
        default:
          if (partnerlinkInfo.error !== undefined) {
            setError(true);
          }
          break;
      }
      setIsRequesting(false);
    } else {
      console.error('[Partnerlink] The scope required to connect is not available.');
      setError(true);
    }
  };

  const disconnect = async () => {
    if (subscriptionsDeleteRnPm) {
      console.debug('[Partnerlink] Disconnecting from Partnerlink...');

      setIsRequesting(true);
      const partnerlinkInfo = await subscriptionsApiSvc.useDeleteSubscriptions(
        client,
        subscriptionOptions,
      );

      switch (partnerlinkInfo.code) {
        case 200: // Ok.
        case 404: // Id not found.
          setPartnerlinkDetails({
            status: ConnectionStatus.DISCONNECTED,
            id: partnerlinkDetails.id,
            message: t('status.disconnected'),
            anyError: false,
          });
          break;
        case 400: // Bad request.
        case 401: // Unauth.
          setError(true);
          break;
        case 500: // Unexpected error.
          setPartnerlinkDetails({
            status: partnerlinkDetails.status,
            id: partnerlinkDetails.id,
            message: t('status.error_disconnecting'),
            anyError: true,
          });
          break;
        default:
          if (partnerlinkInfo.error !== undefined) {
            setError(true);
          }
          break;
      }

      setIsRequesting(false);
    } else {
      console.error('[Partnerlink] The scope required to disconnect is not available.');
      setError(true);
    }
  };

  const textChanged = (text) => {
    setPartnerlinkDetails({
      status: partnerlinkDetails.status,
      id: text,
      message: partnerlinkDetails.message,
      anyError: partnerlinkDetails.anyError,
    });
  };

  const enableCondition = !isFetching && partnerlinkDetails !== null;
  const memoRenderComponent = useMemo(() => {
    if (enableCondition) {
      return (
        <CardWrapper>
          <Card
            appearance="dropShadow"
            content={
              <CardContent>
                <Title>{t('label.title')}</Title>
                <Description>{t('label.description')}</Description>
                <PartnerLinkWrapper>
                  <TextBox
                    id="PartnerlinkId"
                    value={partnerlinkDetails.id}
                    placeholder={t('label.placeholder')}
                    readOnly={partnerlinkDetails.status === ConnectionStatus.CONNECTED}
                    css={{ width: '80%' }}
                    onChange={textChanged}
                  />
                  {partnerlinkDetails.status === ConnectionStatus.CONNECTED ? (
                    <Button loading={isRequesting} css={{ width: '20%' }} onClick={disconnect}>
                      {t('label.disconnect')}
                    </Button>
                  ) : (
                    <Button
                      loading={isRequesting}
                      css={{ width: '20%' }}
                      onClick={connect}
                      disabled={partnerlinkDetails.id === ''}
                    >
                      {t('label.connect')}
                    </Button>
                  )}
                </PartnerLinkWrapper>
                <ConnectionDetailsWrapper>
                  {partnerlinkDetails.anyError ? (
                    <IconXCircle filled color={'colorRed6'} />
                  ) : partnerlinkDetails.status !== ConnectionStatus.UNKNOWN ? (
                    <IconCheckCircle filled color={'colorGreen6'} />
                  ) : (
                    <IconInfo filled color={'colorGray5'} />
                  )}
                  <Message>{partnerlinkDetails.message}</Message>
                </ConnectionDetailsWrapper>
              </CardContent>
            }
          />
        </CardWrapper>
      );
    } else {
      return <></>;
    }
  }, [enableCondition, partnerlinkDetails, isRequesting]);

  return (
    <Wrapper>
      {isFetching ? (
        <LoadingSection />
      ) : (
        <>
          {error ? (
            <ErrorWrapper>
              <IconWrapper>
                <IconWarningAlt size={24} />
              </IconWrapper>
              <ErrorMessage data-testid="partnerlink-errmsg">{t('error.unexpected')}</ErrorMessage>
            </ErrorWrapper>
          ) : (
            <ContentWrapper>
              <>{memoRenderComponent}</>
            </ContentWrapper>
          )}
        </>
      )}
    </Wrapper>
  );
};

const CardWrapper = styled.div`
  width: 100%;
`;

const CardContent = styled.div`
  padding: 15px;
  padding-left: 24px;
`;

const Title = styled.div`
  border-bottom: solid #ebebeb;
  border-width: 1px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  padding-bottom: 10px;
`;

const Description = styled.p`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  min-height: 300px;
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  background-color: #ffffff;
  color: #212121;
  border-color: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  box-sizing: inherit;
  box-shadow: 0 4px 16px rgb(33 33 33 / 10%);
  height: 100px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 20px;
  background: rgba(33, 33, 33, 0.1);
  margin-bottom: 3px;
`;

const ErrorMessage = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  flex-direction: row;
  display: flex;
`;

const PartnerLinkWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
`;

const ConnectionDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  margin-top: 6px;
`;

const Message = styled.div`
  width: 60%;
`;
