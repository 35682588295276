import styled from 'styled-components';
import React from 'react';
import { ProgressIndicator } from '@veneer/core';

export const LoadingSection = () => {
  return (
    <Wrapper>
      <ProgressIndicator />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  background: rgb(255, 255, 255);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
`;
