import { useSelector, RootStateOrAny } from 'react-redux';

export const useStoreState = () => {
  const partnerlinkState = useSelector((state: RootStateOrAny) =>  state.partnerlinkReducer);
  const partnerlinkRnPmState = useSelector((state: RootStateOrAny) =>  state.partnerlinkRnPmReducer);

  return {
    partnerlinkState,
    partnerlinkRnPmState
  };
};
