import React from 'react';
import rootReducer from '../src/store';
import packageInfo from '../package.json';
import { Provider } from 'react-redux';
import { ShellProps } from './types/shell';
import { RootComponent } from '../src';
import { configureStore } from '@reduxjs/toolkit';
import { ShellPropsProvider } from '../src/shellProps';
import { ToastProvider, ThemeProvider, useToast } from '@veneer/core';

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

declare global {
  interface Window {
    Shell: ShellProps;
  }
}

export default function Root(props) {
  const { v1 } = window.Shell as ShellProps;
  const { navigation } = props;
  const namespace = '@jarvis/react-ecp-device-details-partnerlink';
  const themeProviderProps = v1?.theme?.getThemeProviderProperties?.();

  return (
    <section id={namespace}>
      <ThemeProvider {...themeProviderProps}>
        <ToastProvider>
          <ShellPropsProvider
            id={packageInfo.name}
            ecpDeviceV55={sessionStorage.getItem('ecpDeviceV55') ?? false}
            stack={props.stack}
            useToast={useToast}
            authProvider={v1.authProvider}
            navigation={navigation}
          >
            <ToastProvider>
              <Provider store={store}>
                <RootComponent {...props} />
              </Provider>
            </ToastProvider>
          </ShellPropsProvider>
        </ToastProvider>
      </ThemeProvider>
    </section>
  );
}
