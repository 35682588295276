import { useState, useRef, useEffect } from 'react';

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);

  if (hasBeenCalled) {
    return;
  }

  callBack();
  setHasBeenCalled(true);
};

const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useDidMount = () => {
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
  }, []);

  return { didMount };
};

export { useConstructor, usePrevious, useDidMount };
