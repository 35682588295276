import { store } from '../../utils/constants';

const initialState = {
  subscriptionsListRnPm: false,
  subscriptionsReadRnPm: false,
  subscriptionsWriteRnPm: false,
  subscriptionsDeleteRnPm: false
};

const partnerlinkRnPmReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.partnerlinkRnPm.STORE_PERMISSION_SUBSCRIPTIONS_LIST:
      return {
        ...state,
        subscriptionsListRnPm: action.payload,
      };
    case store.partnerlinkRnPm.STORE_PERMISSION_SUBSCRIPTIONS_READ:
      return {
        ...state,
        subscriptionsReadRnPm: action.payload,
      };
    case store.partnerlinkRnPm.STORE_PERMISSION_SUBSCRIPTIONS_WRITE:
      return {
        ...state,
        subscriptionsWriteRnPm: action.payload,
      };
    case store.partnerlinkRnPm.STORE_PERMISSION_SUBSCRIPTIONS_DELETE:
      return {
        ...state,
        subscriptionsDeleteRnPm: action.payload,
      };
    default:
      return state;
  }
};

export default partnerlinkRnPmReducer;
