export default {
  scopes: [
    {
      scope: 'ws-hp.com/partnerlinksvc/subscription.DELETE'
    },
    {
      scope: 'ws-hp.com/partnerlinksvc/subscription.LIST'
    },
    {
      scope: 'ws-hp.com/partnerlinksvc/subscription.READ'
    },
    {
      scope: 'ws-hp.com/partnerlinksvc/subscription.WRITE'
    }
  ]
}