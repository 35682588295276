import moment from 'moment';

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getFormattedDate = (format, date) => {
  moment.locale('en');
  return date ? moment(date).format(format) : '-';
};

export const isMockUpMode = () => {
  if (localStorage.getItem('enable-devices-mockup-data')) {
    return true;
  }
  return process.env.NODE_ENV === 'mockup' || process.env.NODE_ENV === 'demo';
};

export const getMockUpData = async (moduleName: string) => {
  return (await import(`../../mockup/${moduleName}`)).default;
};

export const getKeyNameWithKeyword = (keyword) => {
  const allKeys = Object.keys(localStorage);
  return allKeys?.filter((key) => key.includes(keyword) && key.length > keyword.length)[0];
};

export const checkScopes = (scope) => {
  const userScopes = isMockUpMode()
    ? JSON.parse(localStorage.getItem('jshellUserScopes'))
    : JSON.parse(localStorage.getItem(getKeyNameWithKeyword('jshellUserScopes')));
  return userScopes?.filter((item) => item.scope === scope).length === 1;
};
