// http://ecp.local.portalshell.com:9090/us/en/devices/AQAAAAF-eeuFFAAAAAF5JSDu
export default {
  items: [
    {
      id: '6149ffceb649d87c3a367ba9',
      name: 'Essential Security Task',
      description: 'The initial task',
      active: false,
      deviceGroupId: null,
      createdAt: 1632239566635,
      lastModifiedAt: 1643628927970,
      lastRunAt: 1643628871970,
      policy: {
        id: '6149ffceb649d87c3a367ba8',
        name: 'Essential Security Policy New',
      },
      type: 'assessAndRemediate',
      status: 'idle',
    },
  ],
  offset: 0,
  limit: -1,
  size: 1,
};
