//https://pie-us1.api.ws-hp.com/fleetmgt/v1/assignments?offset=0&limit=-1
export default {
  items: [
    {
      id: '6269fb6fdc55afe25d0bc851',
      groupId: '00000000-0000-0000-0000-000000000000',
      createdAt: 1651112815439,
      lastModifiedAt: 1651112815439,
      lastModifiedBy: null,
      policies: [
        {
          policyId: '60fabb31ea523814a99352a2',
          policyName: 'Essential Security Policy',
          policyAttributes: [
            {
              name: 'ews-password'
            },
            {
              name: 'snmp-v1-v2'
            },
            {
              name: 'snmp-v3'
            },
            {
              name: 'check-latest'
            },
            {
              name: 'pjl-password'
            },
            {
              name: 'pjl-command'
            },
            {
              name: 'fs-access-protocol'
            },
            {
              name: 'https-redirect'
            },
            {
              name: 'remote-fw-update'
            },
            {
              name: 'auto-fw-update'
            }
          ],
          assignmentType: 'assess'
        }
      ]
    }
  ],
    offset: 0,
  limit: -1,
  size: 1
}
