import {
  storeStartRootComponent,
  storeDeviceId,
  storeDeviceUuid,
  storeProductNumber,
  storeSerialNumber,
  storeTenantId
} from '../../store/partnerlink/action';
import { isMockUpMode } from '../../utils/commonMethods';

export const getPartnerlinkStoreActions = (props) => {
  if (isMockUpMode()) {
    return [
      storeStartRootComponent(true), 
      storeDeviceId('en/us/devices/demoId'),
      storeDeviceUuid('demoUuid'),
      storeProductNumber('demoPN'),
      storeSerialNumber('demoSN'),
      storeTenantId('demoTenantId')
    ];
  }
  return [
    storeStartRootComponent(true), 
    storeDeviceId(props.deviceId || '--'),
    storeDeviceUuid(props.deviceInfo?.identity.deviceUuid || '--'),
    storeProductNumber(props.deviceInfo?.identity.makeAndModel.number || '--'),
    storeSerialNumber(props.deviceInfo?.identity.serialNumber || '--'),
    storeTenantId(props.deviceInfo?.ownership.fqTenantId || '--')
  ];
};
