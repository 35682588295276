import AssetsProviderFactory from '../assets/AssetsProviderFactory';
import { JarvisAuthProvider } from '@jarvis/web-http';

type ManageMfeProps = {
  country?: string;
  language?: string;
  authProvider: JarvisAuthProvider;
};

let localProps: ManageMfeProps = {
  language: 'en',
  country: 'US',
  authProvider: null,
};

export const setProps = (props) => {
  localProps.authProvider = props.authProvider;
  localProps.language = props.localization.language;
  localProps.country = props.localization.country;
};

export const getProps = (): ManageMfeProps => {
  return localProps;
};

export const t = (subkey: string, args?: Record<string, string>): string => {
  const props = getProps();
  const assetsProvider = AssetsProviderFactory.create(
    props.language || 'en',
    props.country || 'US',
  );
  return assetsProvider.getText(`ecp-device-details-partnerlink.${subkey}`, args);
};