export default {
  contents: [
    {
      id: '00000000-0000-0000-0000-000000000000',
      name: 'All',
      visibility: 'visible',
      events: { devices: ['none'], users: ['none'], collections: ['none'] },
      devices: 101,
      users: null,
      collections: null,
    },
    {
      id: '1585b9a7-ccf1-4cff-bbce-d997127a25a2',
      name: 'HPPK Group Test',
      visibility: 'visible',
      events: { devices: ['all'] },
      devices: 0,
      users: null,
      collections: null,
    },
    {
      id: '837a159b-17d8-4bc2-ad0f-e329a21a4e94',
      name: 'HPPK Group Test2',
      visibility: 'visible',
      events: { devices: ['all'] },
      devices: -1,
      users: null,
      collections: null,
    },
    {
      id: '837a159b-17d8-4bc2-ad0f-e329a21a4e95',
      name: 'HPPK Group Test3',
      visibility: 'visible',
      events: { devices: ['all'] },
      devices: -1,
      users: null,
      collections: null,
    },
  ],
  totalSize: 3,
};
