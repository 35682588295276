import {
  storeSubscriptionsList,
  storeSubscriptionsRead,
  storeSubscriptionsWrite,
  storeSubscriptionsDelete
} from '../../store/partnerlinkRnPm/action';
import { permission } from '../../utils/constants';
import { checkScopes, getMockUpData, isMockUpMode } from '../../utils/commonMethods';

export const getPartnerlinkRnPmActions = async (props, dispatch) => {
  if (isMockUpMode()) {
    await getMockUpData('get-jshell-scopes').then((data) => {
      localStorage.setItem('jshellUserScopes', JSON.stringify(data.scopes));
    });
  }
  return [
    dispatch(storeSubscriptionsList(checkScopes(permission.subscriptions.LIST))),
    dispatch(storeSubscriptionsRead(checkScopes(permission.subscriptions.READ))),
    dispatch(storeSubscriptionsWrite(checkScopes(permission.subscriptions.WRITE))),
    dispatch(storeSubscriptionsDelete(checkScopes(permission.subscriptions.DELETE))),
  ];
};
