export const deviceDetailOptions = {
  outputLevel: 'minimal',
  key: 'deviceId',
};

export const dateFormat = {
  default: 'MMM DD, YYYY H:mm:ss A', // March 17th, 2022 14:45:30 PM
};

export const service = {
  deviceCache: 'deviceCache',
  fleetMgt: 'fleetMgt',
  collection: 'collection',
  subscriptions: 'subscriptions',
};

export const anchorMenuContainerId = '@jarvis/react-ecp-device-details-partnerlink';

export const store = {
  partnerlink: {
    START_ROOT_COMPONENT: 'partnerlink/startRootComponent',
    DEVICE_ID: 'partnerlink/deviceId',
    DEVICE_UUID: 'partnerlink/deviceUuid',
    PRODUCT_NUMBER: 'partnerlink/productNumber',
    SERIAL_NUMBER: 'partnerlink/serialNumber',
    TENANT_ID: 'partnerlink/tenantId',
  },
  partnerlinkRnPm: {
    STORE_PERMISSION_SUBSCRIPTIONS_LIST: 'partnerlinkRnPm/subscriptionsList',
    STORE_PERMISSION_SUBSCRIPTIONS_READ: 'partnerlinkRnPm/subscriptionsRead',
    STORE_PERMISSION_SUBSCRIPTIONS_WRITE: 'partnerlinkRnPm/subscriptionsWrite',
    STORE_PERMISSION_SUBSCRIPTIONS_DELETE: 'partnerlinkRnPm/subscriptionsDelete',
  }
};

export const permission = {
  subscriptions: {
    LIST: 'ws-hp.com/partnerlinksvc/subscription.LIST',
    READ: 'ws-hp.com/partnerlinksvc/subscription.READ',
    WRITE: 'ws-hp.com/partnerlinksvc/subscription.WRITE',
    DELETE: 'ws-hp.com/partnerlinksvc/subscription.DELETE'
  }
};
