import { Stack, SubscriptionsApiClient } from '@jarvis/web-stratus-client';
import { useContext, useRef } from 'react';
import { shellProps } from '../shellProps';
import { service } from '../utils/constants';

const apiController = () => {
  const { stack, authProvider } = useContext(shellProps);

  const getStackPrefix = (stack: Stack): string => {
    switch (stack) {
      case Stack.dev:
      case Stack.pie:
        return 'daily';
      case Stack.stage:
        return 'staging';
      case Stack.prod:
        return '';
    }
  };

  const getApiUrl = (hostPrefix: string, basePath: string): string => {
    let stackPrefix = getStackPrefix(stack);
    const apiUrl =
      'https://' +
      (hostPrefix.length ? hostPrefix + '.' : '') +
      (stackPrefix.length ? stackPrefix + '-' : '') +
      'cc-gw.hpcloud.hp.com/' +
      basePath;

    return apiUrl;
  };

  const getApiClient = (serviceName) => {
    let client;
    const subscriptionsApiEndPoint = getApiUrl('', 'cors/v1/subscriptions');

    switch (serviceName) {
      case service.subscriptions:
        client = useRef<SubscriptionsApiClient>(null);
        client.current = new SubscriptionsApiClient(subscriptionsApiEndPoint, authProvider);
        break;
    }

    return client.current;
  };

  return {
    getApiClient,
  };
};

export default apiController;
